<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="24" cy="24" r="24" fill="#FDEAE8" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17 27V16C17 14.8954 17.8954 14 19 14H29C30.1046 14 31 14.8954 31 16V27H17ZM23 17.5C23 17.2239 23.2239 17 23.5 17H24.5C24.7761 17 25 17.2239 25 17.5C25 17.7761 24.7761 18 24.5 18H23.5C23.2239 18 23 17.7761 23 17.5Z"
      fill="#B82025"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17 29V32C17 33.1046 17.8954 34 19 34H29C30.1046 34 31 33.1046 31 32V29H17ZM24 32C24.5523 32 25 31.5523 25 31C25 30.4477 24.5523 30 24 30C23.4477 30 23 30.4477 23 31C23 31.5523 23.4477 32 24 32Z"
      fill="#B82025"
    />
  </svg>
</template>
